
$(document).on('click', '.js-anchor', function(event){
    event.preventDefault();
    let anchor = $(this).data('anchor'),
        ANCHOR_BOX = document.querySelector(`#${anchor}`),
        menu = $('.menu__mobile'),
        body = $('body');

    $('.menu__item_active').removeClass('menu__item_active');
    $(this).addClass('menu__item_active');
    $(menu).removeClass('menu__mobile_active');

    $('html, body').animate({
        scrollTop: $(ANCHOR_BOX).offset().top - 120
    }, {
        duration: 300,
        easing: "linear"
    });


    $(body).removeClass('hidden');
});

document.addEventListener("scroll", function(){
    let top = window.pageYOffset || document.documentElement.scrollTop,
        header = $('header');
    if(top > 80){
        $(header).addClass('header_active');
    }else{
        $(header).removeClass('header_active');
    }
}, false);


$(document).ready(function(){
   console.log(location);
   if(location.search === '?comp=y'){
       $('html, body').animate({
           scrollTop: $("#service").offset().top - 120
       }, {
           duration: 300,
           easing: "linear"
       });
   }
});

